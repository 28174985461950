.container {

    height: 100vh;
    width: 100vw;
    display: flex;
}


.backdropText {
    font-family: 'Poppins', sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height, or 120% */

    text-align: center;
    letter-spacing: -0.02em;

    /* Shades/White */
    z-index: 100;
    color: #FFFFFF;
    user-select: none;
    -webkit-user-drag: none;

}

.formLabel {
    user-select: none;
    margin: 10px;
    width: 100%;

    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;

}

.formInput {
    box-shadow: 0 2px 12px 0 rgb(11 22 44 / 5%);
    line-height: 28px;
    padding: 10px 15px;
    width: 100%;
    min-width: 350px;
}

.loginHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    text-align: center;
    letter-spacing: -0.02em;


    color: #171717;
}

.link {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-align: right;
    letter-spacing: -0.02em;
    text-decoration-line: underline !important;

    /* Success/Main (500) */

    color: #3AB44D;
}

.link:hover {
    color: #3AB44D90;
    text-decoration-line: underline !important;
}

.submitBtn {
    margin-top: 10px !important;
    padding: 18px 22px;
    justify-content: center;
    border-radius: 44px;
    background-color: #3ab44d;
    box-shadow: 0 4px 10px 0 rgb(58 180 77 / 20%);
    transition: background-color 300ms ease, transform 300ms ease, color 300ms ease,
        opacity 0.25s ease-in-out;
    ;
    color: #fff;
    font-size: 14px;
    line-height: 1.143em;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    transform-style: preserve-3d;
    width: 100%;
    border: 0 !important;
    cursor: pointer;

}

.submitBtn:hover {
    transform: scale3d(1.06, 1.06, 1.01);
}

.warningMessage {
    margin-top: 0px;
    text-align: center;
    width: 100%;
    background-color: #f4f7ff;
    color: #f87d4e;
    padding: 10px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
    transition: all 0.25s ease-in-out;
    text-overflow: ellipsis;
}

.helpText {
    padding-left: 10px;
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #f87d4e !important;
    transition: all 0.25s ease-in-out;
    height: 18px;
}

.newUserText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */

    letter-spacing: -0.02em;

    /* Neutral/Main (900) */

    color: #171717;
}