.headerContainer {
    background-color: white !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 2px 2px 10px -7px rgba(0, 0, 0, 0.5);
    font-family: Inter, sans-serif;
    position: relative;
    top: 0;
    z-index: 1;
    width: 100%;
    gap: 15px;
    height: 9vh !important;
}


.userName {
    margin-bottom: 0px;
    text-transform: capitalize;
    font-weight: 500;
    color: #555
}

.dropdownBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 10px;
    border-radius: 15px;
    cursor: pointer;
    border: solid 0.25px #bbb;
    padding: 2px 10px !important;
}

.statusContainer {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    line-height: 5px;
    gap: 7px;
    margin-top: 10px;
}

.statusDot {
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background-color: #3AB44D;
}

.addUserFormContainer {
    padding: 25px;
    padding-top: 75px;
    min-width: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
}

.formFieldHalf {
    width: 48%;
    margin-bottom: -5px;
}

.formInputContainer {
    display: flex;
    gap: 5px;
    margin-bottom: -5px;
}

.menu {
    border-radius: 25px;
    min-width: 145px;
    margin-top: -10px;
}

.btnShadow {
    box-shadow: 3px 5px 20px -7px rgba(0, 0, 0, 0.25) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 40px !important;
    border-radius: 25px !important;
    padding: 2px 20px !important;
    margin: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}


.helpBtn {
    margin: 0px 20px !important;
}

.formInput {
    box-shadow: 0 2px 12px 0 rgb(11 22 44 / 5%);
    line-height: 28px;
    padding: 10px 15px;
}