.container {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: flex-start;
    align-items: flex-end;
    box-shadow: 2px 2px 10px -7px rgba(0, 0, 0, 0.5);
    font-family: Inter, sans-serif;
    position: relative;
    transition: all 0.25s ease-in-out;
    padding: 50px 40px;
    border-right: .5px solid rgba(0, 0, 0, 0.25);
}

a {
    text-align: right;
    font-weight: 700;
}

.menuBtn {
    right: 25px;
    top: 47px;
    position: absolute;
    font-size: 22px;
    transition: scale .25s ease-in-out, opacity .5s ease-in-out;

}

.menuBtn:hover {
    transform: scale(1.25);
    transition: transform .25s ease-in-out;
    cursor: pointer;
}

.menuBtnHidden {
    position: absolute;
    opacity: 0;
    transition: opacity .1s ease-in-out;
}

.logoH {
    width: 100px;
    height: 100px;
    align-self: flex-start;
    transition: scale .25s ease-in-out;
    background: url('../../icons/logoH.png') no-repeat 0 0;
    background-size: cover;
    /* background-color: #cccccc; */
}

.logoV {
    width: 100px;
    height: 100px;
    align-self: flex-start;
    transition: scale .25s ease-in-out;
    background: url('../../icons/logoH.png') no-repeat 0 0;
    background-size: cover;
    /* background-color: #cccccc; */
}

.logo {
    align-self: center;
    transition: scale .25s ease-in-out;
    width: 85px;
}


.logo:hover {
    scale: 1.1;
    transition: scale .25s ease-in-out;
    cursor: pointer;
}

.container h3 {
    font-size: 14px;
    text-align: right;
    font-weight: 700;
    color: #333;
}

.navIcon {
    /* filter: invert(45%) sepia(81%) saturate(1269%) hue-rotate(329deg) brightness(105%) contrast(94%); */
    /* filter: invert(80%); */
    /* border: #555 2px solid; */
    width: 35px;
    height: auto;
    margin-top: -5px;
    border-radius: 7px;
}

.linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.linkItemActive {
    opacity: 100%;
    width: 100%;
}

.linkItem {
    opacity: 50%;
    width: 100%;
    transition: opacity 0.15s ease-in-out;
}

.linkItem:hover {
    opacity: 75%;
    transition: opacity 0.15s ease-in-out;
}